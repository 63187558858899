$color-lessblack: #494c4f;
$color-background: rgba(0, 0, 0, 0.3);
$blue-background: rgb(2, 33, 55);

$font-size-header: 1.2rem;
$font-size-content: 1.0rem;


html {
	height: 100%;
}

.application {
	width: 100%;
	height: 100%;
	background-image: url('./bg_2600_full.jpg');
	background-size: cover;
}

body {

	background-size: cover;
	margin: 0;
	height: 100%;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}


#root {
	height: 100%;
}

#app-sidebar {
	background-color: rgba(0, 0, 0, 0.3);
}

.app-header {
	background-color: $color-background;
}

.hamburger-menu {
	margin-right: .5rem !important;
	padding: 2px 2px 0 6px;
}

#navigation-headline {
	height: 50px;
	background-color: rgba(0, 0, 0, 0.3);
}


.p-sidebar {
	background: rgba(2, 33, 55, .80);
	overflow: auto;
}

.p-menu {
	background: rgb(2, 33, 55);
}

.p-menu .p-submenu-header {
	background: none;
}

.p-panelmenu .p-panelmenu-header>a {
	background: rgb(2, 33, 55);
}


.p-button {
	color: #fff;
	background: $blue-background;
	border: 1px solid silver;
	padding: .5rem .75rem;
	font-size: 1rem;
	transition: background-color .15s, border-color .15s, box-shadow .15s;
	border-radius: 4px;
}

.p-datatable {
	background: none !important;
	color: rgba(255, 255, 255, 0.87);

	.p-datatable-scrollable-header {
		background-color: rgba(0, 0, 0, 0.4);
		border: 1px solid silver !important;
	}

	.p-datatable-header {
		background-color: rgba(0, 0, 0, 0.4);
		border: 1px solid silver !important;
	}

	.p-datatable-tbody {

		tr,
		td {
			background: none !important;
			border-bottom-width: 1px;
			border-color: silver !important
		}
	}

	.p-datatable-thead>tr>th {
		background-color: rgba(0, 0, 0, 0.2) !important;
		border: none;

		.weekend {
			background-color: rgba(0, 0, 0, 0.6) !important;
		}
	}
}

.p-card {
	background: none;
	box-shadow: none;
}

.p-fieldset {
	background-color: rgba(0, 0, 0, 0.2);
	border: 1px color silver;
}

.p-fieldset-legend {
	background-color: rgb(1, 48, 75) !important;

}

.p-tabview .p-tabview-panels {
	background: none;
}

#nav-page-tabview {
	background: none !important;
	border: 1px color silver;

}

.p-tabview-selected {
	background-color: rgba(0, 0, 0, 0.4) !important;
}

.p-tabview .p-tabview-nav li li.p-highlight .p-tabview-nav-link {
	background-color: rgba(0, 0, 0, 0.4) !important;
	border-color: #3f4b5b #3f4b5b #2a323d #3f4b5b;
	color: rgba(255, 255, 255, 0.6);
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	background-color: rgba(0, 0, 0, 0.4) !important;
}

#fieldset_changePassword .p-fluid {
	width: 100%;

}

#fieldset_changePassword .p-field {
	margin-bottom: 0.5rem;
}

#fieldset_changePassword .p-password input {
	width: 100%;
	letter-spacing: 0.1em;
}

#fieldset_changePassword .p-col-fixed {
	width: 320px;
}

#fieldset_changePassword .p-fieldset-content {
	padding: 0px;
}

#dialog-editdialog {
	width: 600px;
}

#userInactiveIntervall input {
	background-color: rgb(1, 48, 75) !important;
	background: none !important;
	border: 1px solid darkgray;
	height: 30px;
	width: 30px;
}

#userInactiveIntervall .p-button.p-button-icon-only {
	width: 1.5rem;
	padding: 0px;
}

#userInactiveIntervall .p-inputtext-sm {
	padding: 3px 5px;
}

#userInactiveIntervall .p-inputtext {
	padding: 2px 5px;
}


.p-dialog .p-dialog-header {
	background-color: $blue-background;
}

.userprofile {
	background-color: $color-background;
	min-height: 300px;

	.userprofilelist {
		background: #20262e;
		opacity: 0.65;
		padding: 0.5rem 0.75rem;
		color: rgba(255, 255, 255, 0.87);
	}
}

.default-dataView {
	width: 1480px;
	margin-top: 20px;


	input {
		background: none;
	}

	.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
		border: 1px solid silver;
		border-width: 1px 1px 0 1px;
	}

	.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th .p-datatable-table {
		.p-inputtext {
			color: silver;
			background: none;
		}
	}

	.p-filter-column,
	.p-column-filter-row .p-inputtext {
		border: 1px solid silver !important;
		border-width: 1px 1px 1px 1px !important;
	}

	.p-paginator {
		border-color: silver;
	}

	.p-dropdown {
		background: none;
		border-color: silver;
	}

	.p-multiselect {
		background: none;
		border-color: silver;
		padding: 0px;
	}

	.p-paginator {
		background: $color-background;
	}

	.p-column-filter-clear-button {
		width: auto
	}

	#statusRowFilterJobtypeTemplate {
		width: 200px;
	}

	#-statusRowFilterTemplate {
		background: none;
	}
}

.graph24h {

	.xaxis,
	.totalvalues {
		color: white;
		font-weight: 100
	}

	.p-datepicker table td>span {
		height: 1.5rem !important;
	}
}

.calenderEVD {
	max-width: 800px;
	width: 100%;

	.fc {
		width: 100%
	}

	.fc .fc-daygrid-week-number {
		color: white
	}

	.fc.fc-theme-standard .fc-view-harness .fc-view {
		width: 100%;
		background: rgba(0, 0, 0, .2);
		border-radius: 4px;

		@media only screen and (min-width:320px) and (max-width:480px) {
			padding: 5px;
			background: none !important;
			box-shadow: none !important;
		}
	}

	.fc .fc-daygrid-day-frame {
		height: 50px;
	}
}


.forcemanager {
	max-width: 1000px;
	width: 100%;
	padding: 0.5rem;

	.groups {
		width: 100%;
		margin-bottom: 10px;

		.header {
			background-color: rgba(0, 0, 0, 0.4) !important;
			padding: 10px;
			font-size: $font-size-header;
		}

		.tableheader {
			background-color: rgba(255, 255, 255, 0.5) !important;
			color: rgb(39, 39, 39);
			padding: 10px;
			width: 100%;
			font-size: $font-size-content;
		}
	}

	.orgfunctions,
	.groups {
		.tableentry {
			background-color: rgba(0, 0, 0, 0.2) !important;
			width: 100%;
			padding: 10px;
			font-size: $font-size-content;
		}

		.tablecolumn {
			padding: 1px;
		}

	}

	.evd {
		background-color: rgba(0, 0, 0, 0.4) !important;
		margin-bottom: 40px;
		padding: 5px;
		min-height: 100px;
		font-size: $font-size-header;
	}

	.orgfunctions {
		margin-bottom: 40px;
		width: 100%;

		.header {
			background-color: rgba(0, 0, 0, 0.4) !important;
			padding: 10px;
			font-size: $font-size-header;
		}


		.orgfunction-entry {
			border-bottom-width: 1px;
			border-bottom-style: solid;
		}

		.EVD {
			border-bottom-color: rgb(255, 255, 2)
		}

		.ZF {
			border-bottom-color: rgb(1, 176, 240)
		}

		.GF {
			border-bottom-color: rgb(255, 0, 0)
		}
	}
}